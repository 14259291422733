<template>
	<please-select-utility alert-class="ma-3">
		<div>
			<template v-if="!imodel.loaded"><v-progress-linear indeterminate /></template>
			<template v-else>
				<div v-show="!isEdit">
					<Search @filter="filter = $event" :defaults="defaultSearch" />
					<v-toolbar flat>
						<v-toolbar-title>{{ imodel.Title }}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-btn @click="onAdd" dark color="primary"><v-icon left>mdi-plus</v-icon> Нов запис</v-btn>
					</v-toolbar>
					<list
						ref="list"
						headers="title,type,users_ids"
						:filter="filter2"
						@edit="onEdit"
						sort-by="title"
						sort-desc
					/>
				</div>
				<edit-column
					:id="id"
					:constrains="{utility_code:selectedUtility}"
					hide-constrained
					v-if="isEdit"
					@saveclose="onSaveClose"
					@save="onSave"
					@close="onEditCancel"
					ref="EditForm"
				>
					<template #field.users_ids="{row,err,disabled}">
						<edit-input
							label=""
							outlined dense
							name="users_ids"
							:data="row"
							:errors="err"
							:disabled="disabled"
							item-text="text"
						/>
					</template>
				</edit-column>
			</template>
		</div>
	</please-select-utility>
</template>

<script>
import Model from './Model';
import EditInput from "@/ittijs/Inputs/EditInput.vue";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import {cloneDeep} from "lodash";
import List from "@/ittijs/List.vue";
import EditColumn from "@/ittijs/EditColumn.vue";
import Search from "@/ittijs/Search.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import IndexMixin from "@/ittijs/IndexMixin";

const imodel = ITTIModelProvider.getModel(Model);

export default {

	mixins: [
		IndexMixin,
		SelectedUtilityMixin,
	],

	components: {
		PleaseSelectUtility,
		EditColumn,
		Search,
		List,
		EditInput,
	},

	data(){
		return {
			defaultSearch: {
				rules:[],
				groups:{
					g1:{rules:{}
					}
				},
			},
			filter: {},
			imodel: imodel,
		};
	},

	computed: {
		filter2: function(){
			if (!this.selectedUtility) return this.filter;
			const f2 = cloneDeep(this.filter);
			f2.groups = {
				constrains: {
					rules: {
						utility_code: {
							field: 'utility_code',
							op: 'eq',
							value: this.selectedUtility,
						}
					}
				}
			};
			return f2;
		},
	},

	provide: function () {
		return {
			imodel,
		};
	},

}
</script>